<template>
  <div>
    <div class="mt-3 p-2">
      <b-container fluid class="mb-2">
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
        <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
          <h5>Edit Announcement Details</h5>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Title:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-input
                    id="input-1"
                    v-model="form.title"
                    type="text"
                    placeholder="Enter Title"
                    :state="isNameValid"
                    required
                  ></b-form-input>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>

              <b-form-group id="input-group-2" label="URL:" label-for="input-2">
                <ValidationProvider rules="alpha" v-slot="{ errors }">
                  <b-form-input
                    id="input-2"
                    v-model="form.url"
                    placeholder="Enter URL"
                  ></b-form-input>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-textarea
                    id="input-2"
                    v-model="form.description"
                    placeholder="Enter description..."
                    rows="5"
                    max-rows="8"
                    :state="isDescriptionValid"
                  ></b-form-textarea>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Images:"
                label-for="input-3"
              >
                <b-form-file
                  accept="image/*"
                  multiple
                  id="file2"
                  ref="file2"
                  :state="Boolean(tempImages)"
                  placeholder="Choose multiple images or drop them here..."
                  drop-placeholder="Drop file here..."
                  @input="uploadImages"
                ></b-form-file>
              </b-form-group>
              <b-row v-if="form.images[0] != ''">
                <b-col cols="3" v-for="image in form.images" :key="image">
                  <img
                    width="100%"
                    height="70%"
                    style="border-radius: 11px"
                    :src="image"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-spinner
                    class="text-center"
                    v-if="isImageUploading"
                    variant="success"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                label="Cover Image:"
                label-for="input-2"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="form.image"
                  :state="Boolean(form.image)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="uploadImage"
                ></b-form-file>
              </b-form-group>
              <img
                :src="form.image"
                alt="Existing Image"
                class="d-block mb-4"
                height="200px"
                width="300px"
              />
              <b-form-group
                id="input-group-3"
                label="Videos:"
                label-for="input-4"
              >
                <b-form-file
                  multiple
                  id="file3"
                  ref="file3"
                  accept="video/*"
                  :state="Boolean(tempVideos)"
                  placeholder="Choose multiple Videos or drop them here..."
                  drop-placeholder="Drop file here..."
                  @input="uploadVideos"
                ></b-form-file>
              </b-form-group>
              <b-row v-if="form.videos[0] != ''">
                <b-col cols="3" v-for="video in form.videos" :key="video">
                  <video width="100%" height="70%" controls>
                    <source :src="video" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-center">
                  <b-spinner
                    class="text-center"
                    v-if="isVideoUploading"
                    variant="success"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </b-col>
              </b-row>
            </div>
            <div class="col-md-6">
              <b-button
                size="sm"
                :disabled="isDisable"
                variant="success"
                @click="submitForm()"
              >
                Update
              </b-button>
            </div>
          </div>
        </b-form>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      farmDetails: '',
      form: {
        title: '',
        description: '',
        url: '',
        image: null,
        images: [],
        videos: [],
      },
      isDisable: false,
      success: false,
      tempVideos: [],
      tempImages: [],
      isVideoUploading: false,
      isImageUploading: false,
    };
  },
  mounted() {
    this.getAnnouncementDetails();
  },
  computed: {
    isNameValid() {
      return this.form.title.length > 0;
    },

    isDescriptionValid() {
      return this.form.description.length > 0;
    },
  },

  methods: {
    getAnnouncementDetails() {
      let announcementID = this.$route.params.id;
      window.axios.get('/social-media/' + announcementID, {}).then((resp) => {
        console.log(resp.data.success);
        if (resp.data.success) {
          this.form = resp.data.data;
        }
      });
    },

    submitForm() {
      let fileState = Boolean(this.form.image);
      if (
        this.form.title.length == 0 ||
        this.form.description == 0 ||
        !fileState
      ) {
        this.$bvToast.toast(
          'Title, Description and Cover Image are required field!',
          {
            title: `Error`,
            variant: 'danger',
            solid: true,
          }
        );
      } else {
        let announcementID = this.$route.params.id;
        let formData = new FormData();
        formData.append('title', this.form.title);
        formData.append('description', this.form.description);
        formData.append('url', this.form.url);
        formData.append('image', this.form.image);
        if (this.form.images.length > 0) {
          this.form.images.forEach((image) => {
            formData.append('images', image);
          });
        } else {
          formData.append('images', this.form.images);
        }

        if (this.form.videos.length > 0) {
          this.form.videos.forEach((video) => {
            formData.append('videos', video);
          });
        } else {
          formData.append('videos', this.form.videos);
        }

        window
          .axios({
            method: 'put',
            url: 'social-media/' + announcementID,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.success = resp.data.message;
            }
            setTimeout(
              () => (
                (this.success = false), this.$router.push('/announcement')
              ),
              3000
            );
          });
      }
    },
    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },

    uploadVideos() {
      this.form.videos = [];

      this.tempVideos = this.$refs.file3.files;
      const bodyFormData = new FormData();
      this.$refs.file3.files.forEach((element) => {
        bodyFormData.append('files', element);
      });

      this.isDisable = true;
      this.isVideoUploading = true;
      window
        .axios({
          method: 'post',
          url: 'upload',
          data: bodyFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((resp) => {
          resp.data.data.forEach((data) => {
            this.form.videos.push(data.url);
          });
          this.isDisable = false;
          this.isVideoUploading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    uploadImages() {
      this.form.images = [];
      this.tempImages = this.$refs.file2.files;
      const bodyFormData = new FormData();
      this.$refs.file2.files.forEach((element) => {
        bodyFormData.append('files', element);
      });

      this.isDisable = true;
      this.isImageUploading = true;

      window
        .axios({
          method: 'post',
          url: 'upload',
          data: bodyFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((resp) => {
          resp.data.data.forEach((data) => {
            this.form.images.push(data.url);
          });

          console.log('Images');
          console.log(this.form.images);
          this.isDisable = false;
          this.isImageUploading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
